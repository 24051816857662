.button-container {
  margin-bottom: -12px;
}

.button-container.simple {
  margin-bottom: 0;
}

.button-container > * {
  margin-bottom: 12px;
  margin-right: 8px;
}

.button-container.simple > * {
  margin-bottom: 0;
}

.button-container > :last-child {
  margin-right: 0;
}

.button {
  cursor: pointer;
  -o-transition: background-color .25s cubic-bezier(.075, .82, .165, 1);
  -khtml-transition: background-color .25s cubic-bezier(.075, .82, .165, 1);
  vertical-align: middle;
  transition: background-color .25s cubic-bezier(.075, .82, .165, 1);
  display: inline-flex;
}

.button.small {
  font-size: 16px;
  line-height: 24px;
}

.button.simple {
  -o-border-radius: 96px 96px 96px 96px;
  -ms-border-radius: 96px 96px 96px 96px;
  -khtml-border-radius: 96px 96px 96px 96px;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  border-radius: 96px;
  padding: 0 8px;
}

.button.simple.border {
  border: 1px solid #fff;
}

.button.simple.padded {
  padding: 0 10px;
}

.button.simple.padded-large {
  padding: 4px 14px;
}

.button.simple.rounded {
  padding: 6px;
}

.button.simple.enabled {
  background-color: #50cb93;
}

.button.simple.file {
  position: relative;
}

.button.simple:focus, .button.simple:hover {
  background-color: #50cb93;
}

.button.simple.red:focus, .button.simple.red:hover {
  background-color: #e63946;
}

.button.simple:active {
  background-color: #4ca67d;
}

.button.simple.red:active {
  background-color: #bf2a37;
}

.button.simple > img {
  vertical-align: middle;
  width: 13px;
  margin-right: 6px;
}

.button.simple.medium > img {
  width: 20px;
}

.button.simple.large > img {
  width: 28px;
}

.button.simple.very-large > img {
  width: 38px;
}

.button.simple > span {
  vertical-align: middle;
  display: inline-block;
}

.button.simple.no-text > img {
  margin-top: 0;
  margin-right: 0;
}

.button.simple.file > input[type="file"] {
  cursor: pointer;
  opacity: 0;
  -o-opacity: 0;
  -ms-opacity: 0;
  -moz-opacity: 0;
  -khtml-opacity: 0;
  -webkit-opacity: 0;
  vertical-align: top;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.button.simple.file > input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
}

.button-increment {
  display: inline-block;
}

.button-increment > .value {
  margin: 0 8px;
}

.button-increment > .prefix {
  margin-left: 8px;
}

.button-increment > .suffix {
  margin-right: 8px;
}

.panel-tab {
  flex-direction: column;
  display: flex;
  overflow: auto;
}

.panel-tab > .tab-selectors {
  margin-bottom: 26px;
  line-height: 30px;
}

.panel-tab > .tab-selectors > .tab-selector {
  cursor: pointer;
  opacity: .8;
  -o-opacity: .8;
  -ms-opacity: .8;
  -moz-opacity: .8;
  -khtml-opacity: .8;
  -webkit-opacity: .8;
  -o-transition: opacity .5s cubic-bezier(.075, .82, .165, 1);
  -khtml-transition: opacity .5s cubic-bezier(.075, .82, .165, 1);
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  border-bottom: 2px solid #0000;
  margin-right: 18px;
  font-size: 22px;
  transition: opacity .5s cubic-bezier(.075, .82, .165, 1);
  display: inline-block;
}

.panel-tab > .tab-selectors > .tab-selector:last-child {
  margin-right: 0;
}

.panel-tab > .tab-selectors > .tab-selector:hover {
  opacity: 1;
  -o-opacity: 1;
  -ms-opacity: 1;
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
}

.panel-tab > .tab-selectors > .tab-selector.selected {
  opacity: 1;
  -o-opacity: 1;
  -ms-opacity: 1;
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  border-bottom: 2px solid #fff;
}

.panel-tab > .tab-containers > .tab-container {
  display: none;
}

.panel-tab > .tab-containers > .tab-container.selected {
  display: block;
}

.panel-tab > .tab-containers > .tab-container.flex {
  flex-direction: column;
  overflow: auto;
}

.panel-tab > .tab-containers > .tab-container.flex.selected {
  display: flex;
}

.display {
  max-width: 100%;
}

.display.fullscreen {
  z-index: 6;
  background-color: #000;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.display > .magnify-button {
  cursor: pointer;
  -o-transition: transform .35s cubic-bezier(.075, .82, .165, 1);
  -khtml-transition: transform .35s cubic-bezier(.075, .82, .165, 1);
  transition: transform .35s cubic-bezier(.075, .82, .165, 1);
  display: inline-block;
}

.display > .magnify-button:hover {
  -khtml-transform: scale(1.3, 1.3);
  transform: scale(1.3);
}

.display > .magnify-button:active {
  -khtml-transform: scale(1, 1);
  transform: scale(1);
}

.display > .display-minimize {
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  display: none;
  position: absolute;
  bottom: 22px;
  right: 22px;
}

@media only screen and (width <= 1120px) {
  .display > .display-minimize {
    bottom: initial;
    top: 22px;
  }
}

.display > .display-minimize > img {
  width: 32px;
  height: 32px;
}

.display.fullscreen > .display-minimize {
  display: block;
}

.display > .display-frame {
  -o-border-radius: 8px 8px 8px 8px;
  -ms-border-radius: 8px 8px 8px 8px;
  -khtml-border-radius: 8px 8px 8px 8px;
  -o-box-shadow: 0px 0px 14px #0009;
  -ms-box-shadow: 0px 0px 14px #0009;
  -khtml-box-shadow: 0px 0px 14px #0009;
  background-color: #000;
  border: 2px solid #58b09c;
  border-radius: 8px;
  padding: 8px;
  font-size: 0;
  box-shadow: 0 0 14px #0009;
}

@media only screen and (width <= 1120px) {
  .display > .display-frame {
    box-shadow: none;
    -o-box-shadow: none;
    -ms-box-shadow: none;
    -moz-box-shadow: none;
    -khtml-box-shadow: none;
    -webkit-box-shadow: none;
  }
}

.display.fullscreen > .display-frame {
  -o-box-shadow: 0px 0px 12px #0000003d;
  -ms-box-shadow: 0px 0px 12px #0000003d;
  -khtml-box-shadow: 0px 0px 12px #0000003d;
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: 0 0 12px #0000003d;
}

.display > .display-frame > .display-canvas {
  image-rendering: pixelated;
  -o-outline: none;
  -ms-outline: none;
  -moz-outline: none;
  -khtml-outline: none;
  -webkit-outline: none;
  -o-transition: width .5s cubic-bezier(.075, .82, .165, 1), height .5s cubic-bezier(.075, .82, .165, 1);
  -khtml-transition: width .5s cubic-bezier(.075, .82, .165, 1), height .5s cubic-bezier(.075, .82, .165, 1);
  outline: none;
  max-width: 100%;
  transition: width .5s cubic-bezier(.075, .82, .165, 1), height .5s cubic-bezier(.075, .82, .165, 1);
}

.footer > .footer-background {
  -webkit-filter: blur(1rem);
  -o-filter: blur(1rem);
  -ms-filter: blur(1rem);
  -moz-filter: blur(1rem);
  -khtml-filter: blur(1rem);
  z-index: 1;
  width: 100%;
  height: 40px;
  position: fixed;
  bottom: 0;
  left: 0;
}

.footer > .footer-contents {
  text-align: center;
  z-index: 1;
  width: 100%;
  height: 40px;
  padding: 0;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  left: 0;
}

.help {
  flex-direction: column;
  display: flex;
  overflow: auto;
}

.info {
  vertical-align: top;
  margin: 0;
  font-size: 24px;
}

.info.small {
  font-size: 18px;
}

.info > dt {
  clear: both;
  float: left;
  margin-top: 12px;
}

.info > dt:first-of-type {
  margin-top: 0;
}

.info > dd {
  float: right;
  margin-top: 12px;
}

.info > dd:first-of-type {
  margin-top: 0;
}

.info:after {
  clear: both;
  content: "";
  display: block;
}

.keyboard-chip8 {
  -webkit-tap-highlight-color: transparent;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  font-size: 0;
  touch-callout: none !important;
  -o-touch-callout: none !important;
  -ms-touch-callout: none !important;
  -moz-touch-callout: none !important;
  -khtml-touch-callout: none !important;
  -webkit-touch-callout: none !important;
}

.keyboard-chip8 > .keyboard-line {
  margin-bottom: 12px;
}

.keyboard-chip8 > .keyboard-line:last-child {
  margin-bottom: 0;
}

.keyboard-chip8 .key {
  -o-border-radius: 5px 5px 5px 5px;
  -ms-border-radius: 5px 5px 5px 5px;
  -khtml-border-radius: 5px 5px 5px 5px;
  cursor: pointer;
  text-align: center;
  border: 2px solid #fff;
  border-radius: 5px;
  min-width: 48px;
  height: 48px;
  margin-right: 14px;
  padding: 0 6px;
  font-size: 38px;
  line-height: 46px;
  display: inline-block;
}

.keyboard-chip8 .key:last-child {
  margin-right: 0;
}

.keyboard-chip8 .key.pressed {
  background-color: #50cb93;
}

.keyboard-container.fullscreen {
  pointer-events: none;
  z-index: 10;
  width: 100%;
  position: fixed;
  bottom: 30px;
  left: 0;
}

@media only screen and (width <= 1120px) {
  .keyboard-container.fullscreen {
    bottom: 0;
  }
}

.keyboard-gb {
  -webkit-tap-highlight-color: transparent;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  font-size: 0;
  touch-callout: none !important;
  -o-touch-callout: none !important;
  -ms-touch-callout: none !important;
  -moz-touch-callout: none !important;
  -khtml-touch-callout: none !important;
  -webkit-touch-callout: none !important;
}

.keyboard-gb.fullscreen {
  -o-border-radius: 24px 24px 24px 24px;
  -ms-border-radius: 24px 24px 24px 24px;
  -khtml-border-radius: 24px 24px 24px 24px;
  pointer-events: initial;
  background: #0000004d;
  border-radius: 24px;
  max-width: 600px;
  margin: 0 auto;
  padding: 18px 0;
}

@media only screen and (width <= 600px) {
  .keyboard-gb.fullscreen {
    -o-border-radius: 0px 0px 0px 0px;
    -ms-border-radius: 0px 0px 0px 0px;
    -khtml-border-radius: 0px 0px 0px 0px;
    border-radius: 0;
    padding: 12px 0;
  }
}

.keyboard-gb > .keyboard-line {
  -o-border-radius: 24px 24px 24px 24px;
  -ms-border-radius: 24px 24px 24px 24px;
  -khtml-border-radius: 24px 24px 24px 24px;
  border-radius: 24px;
  margin-bottom: 12px;
}

.keyboard-gb > .keyboard-line:last-child {
  margin-bottom: 0;
}

.keyboard-gb .key {
  -o-border-radius: 5px 5px 5px 5px;
  -ms-border-radius: 5px 5px 5px 5px;
  -khtml-border-radius: 5px 5px 5px 5px;
  cursor: pointer;
  text-align: center;
  border: 2px solid #fff;
  border-radius: 5px;
  min-width: 48px;
  height: 48px;
  margin-right: 14px;
  padding: 0 8px;
  font-size: 38px;
  line-height: 46px;
  display: inline-block;
}

.keyboard-gb .key:last-child {
  margin-right: 0;
}

.keyboard-gb .key.pressed {
  background-color: #50cb93;
}

.keyboard-gb > .dpad {
  float: left;
  text-align: center;
  width: 172px;
}

@media only screen and (width <= 600px) {
  .keyboard-gb > .dpad {
    width: 160px;
  }
}

.keyboard-gb > .dpad > .dpad-top {
  margin-bottom: -3px;
}

.keyboard-gb > .dpad > .dpad-bottom {
  margin-top: -3px;
}

.keyboard-gb > .dpad .key {
  border: none;
  padding: 0;
  font-size: 24px;
}

.keyboard-gb > .dpad .key.pressed {
  background-color: #50cb93;
}

.keyboard-gb > .dpad .key.up {
  -o-border-radius: 6px 6px 0px 0px;
  -ms-border-radius: 6px 6px 0px 0px;
  -khtml-border-radius: 6px 6px 0px 0px;
  border: 3px solid #fff;
  border-bottom-color: #0000;
  border-radius: 6px 6px 0 0;
}

.keyboard-gb > .dpad .key.left {
  -o-border-radius: 6px 0px 0px 6px;
  -ms-border-radius: 6px 0px 0px 6px;
  -khtml-border-radius: 6px 0px 0px 6px;
  border: 3px solid #fff;
  border-right-color: #0000;
  border-radius: 6px 0 0 6px;
  margin-right: 44px;
}

.keyboard-gb > .dpad .key.right {
  -o-border-radius: 0px 6px 6px 0px;
  -ms-border-radius: 0px 6px 6px 0px;
  -khtml-border-radius: 0px 6px 6px 0px;
  border: 3px solid #fff;
  border-left-color: #0000;
  border-radius: 0 6px 6px 0;
  margin-left: -3px;
  margin-right: 0;
}

.keyboard-gb > .dpad .key.center {
  -o-border-radius: 0px 0px 0px 0px;
  -ms-border-radius: 0px 0px 0px 0px;
  -khtml-border-radius: 0px 0px 0px 0px;
  pointer-events: none;
  border-radius: 0;
  margin-right: 0;
}

.keyboard-gb > .dpad .key.down {
  -o-border-radius: 0px 0px 6px 6px;
  -ms-border-radius: 0px 0px 6px 6px;
  -khtml-border-radius: 0px 0px 6px 6px;
  border: 3px solid #fff;
  border-top-color: #0000;
  border-radius: 0 0 6px 6px;
}

.keyboard-gb > .action {
  float: right;
  margin-right: 18px;
}

@media only screen and (width <= 600px) {
  .keyboard-gb > .action {
    margin-right: 12px;
  }
}

.keyboard-gb > .action > .key {
  -o-border-radius: 32px 32px 32px 32px;
  -ms-border-radius: 32px 32px 32px 32px;
  -khtml-border-radius: 32px 32px 32px 32px;
  border-width: 3px;
  border-radius: 32px;
  width: 58px;
  height: 58px;
  font-size: 30px;
  font-weight: 900;
  line-height: 52px;
}

.keyboard-gb > .action > .key.a {
  position: relative;
  top: 10px;
  right: 0;
}

.keyboard-gb > .action > .key.b {
  position: relative;
  top: 50px;
  right: 2px;
}

.keyboard-gb > .options {
  margin-top: 32px;
}

@media only screen and (width <= 600px) {
  .keyboard-gb > .options {
    margin-top: 22px;
  }
}

.keyboard-gb > .options > .key {
  min-width: 100px;
  height: 30px;
  margin-left: 16px;
  margin-right: 16px;
  font-size: 14px;
  font-weight: 900;
  line-height: 26px;
}

.keyboard-gb > .break {
  clear: both;
}

.link {
  color: #fff;
  -o-transition: border .25s cubic-bezier(.075, .82, .165, 1);
  -khtml-transition: border .25s cubic-bezier(.075, .82, .165, 1);
  border-bottom: 2px dotted #fff;
  text-decoration: none;
  transition: border .25s cubic-bezier(.075, .82, .165, 1);
}

.link:hover {
  border-bottom: 2px solid #fff;
}

.link.click {
  cursor: pointer;
}

.modal {
  opacity: 0;
  -o-opacity: 0;
  -ms-opacity: 0;
  -moz-opacity: 0;
  -khtml-opacity: 0;
  -webkit-opacity: 0;
  pointer-events: none;
  text-align: center;
  -o-transition: opacity .3s cubic-bezier(.075, .82, .165, 1);
  -khtml-transition: opacity .3s cubic-bezier(.075, .82, .165, 1);
  z-index: 10;
  background-color: #141414f2;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 12px;
  transition: opacity .3s cubic-bezier(.075, .82, .165, 1);
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.modal.visible {
  opacity: 1;
  -o-opacity: 1;
  -ms-opacity: 1;
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  pointer-events: initial;
  -o-transition: opacity .5s cubic-bezier(.075, .82, .165, 1);
  -khtml-transition: opacity .5s cubic-bezier(.075, .82, .165, 1);
  transition: opacity .5s cubic-bezier(.075, .82, .165, 1);
}

.modal > .modal-window {
  -o-border-radius: 6px 6px 6px 6px;
  -ms-border-radius: 6px 6px 6px 6px;
  -khtml-border-radius: 6px 6px 6px 6px;
  -o-box-shadow: 0px 0px 8px #00000080;
  -ms-box-shadow: 0px 0px 8px #00000080;
  -khtml-box-shadow: 0px 0px 8px #00000080;
  -o-outline: none;
  -ms-outline: none;
  -moz-outline: none;
  -khtml-outline: none;
  -webkit-outline: none;
  text-align: left;
  -khtml-transform: scale(.96);
  -o-transition: transform .3s cubic-bezier(.075, .82, .165, 1), margin-top .5s cubic-bezier(.075, .82, .165, 1);
  -khtml-transition: transform .3s cubic-bezier(.075, .82, .165, 1), margin-top .5s cubic-bezier(.075, .82, .165, 1);
  background-color: #264653;
  border-radius: 6px;
  outline: none;
  flex-direction: column;
  width: 480px;
  max-width: 100%;
  max-height: 100%;
  margin-top: 30px;
  padding: 24px;
  transition: transform .3s cubic-bezier(.075, .82, .165, 1), margin-top .5s cubic-bezier(.075, .82, .165, 1);
  display: flex;
  overflow-y: auto;
  transform: scale(.96);
  box-shadow: 0 0 8px #00000080;
}

.modal.visible > .modal-window {
  pointer-events: all;
  -khtml-transform: scale(1);
  -o-transition: transform .5s cubic-bezier(.075, .82, .165, 1), margin-top .4s cubic-bezier(.075, .82, .165, 1);
  -khtml-transition: transform .5s cubic-bezier(.075, .82, .165, 1), margin-top .4s cubic-bezier(.075, .82, .165, 1);
  margin-top: 0;
  transition: transform .5s cubic-bezier(.075, .82, .165, 1), margin-top .4s cubic-bezier(.075, .82, .165, 1);
  transform: scale(1);
}

.modal > .modal-window > .modal-top-buttons {
  position: absolute;
  top: 14px;
  right: 14px;
}

.modal > .modal-window > .modal-title {
  text-align: left;
  margin-top: 0;
  font-size: 32px;
}

.modal > .modal-window > .modal-contents {
  flex-direction: column;
  display: flex;
  overflow: auto;
}

.modal > .modal-window > .modal-text {
  margin: 0;
  font-size: 20px;
  line-height: 24px;
  overflow: auto;
}

.modal > .modal-window > .modal-buttons {
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  margin-top: 24px;
  font-size: 22px;
}

.modal > .modal-window > .modal-buttons > .button.simple {
  min-width: 120px;
  margin-right: 12px;
  display: inline-block;
}

.modal > .modal-window > .modal-buttons > .button.simple:last-child {
  margin-right: 0;
}

.overlay {
  opacity: 0;
  -o-opacity: 0;
  -ms-opacity: 0;
  -moz-opacity: 0;
  -khtml-opacity: 0;
  -webkit-opacity: 0;
  pointer-events: none;
  text-align: center;
  -o-transition: opacity .4s cubic-bezier(.075, .82, .165, 1);
  -khtml-transition: opacity .4s cubic-bezier(.075, .82, .165, 1);
  z-index: 10;
  background-color: #50cb93f2;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 48px;
  transition: opacity .4s cubic-bezier(.075, .82, .165, 1);
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.overlay.visible {
  opacity: 1;
  -o-opacity: 1;
  -ms-opacity: 1;
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
}

.overlay .overlay-image {
  margin-top: 16px;
}

.overlay .overlay-image > img {
  width: 64px;
}

dt.pair.name-click, dd.pair.value-click {
  cursor: pointer;
}

dt.pair-state, dd.pair-state {
  margin-top: 0;
}

dd.pair-state {
  font-size: 0;
}

.pair-state .pair-state-description {
  color: #f0f0f0;
  margin-top: 6px;
  font-size: 16px;
}

.pair-state .pair-state-buttons {
  margin-top: 26px;
  font-size: 17px;
}

.pair-state .pair-state-buttons > .link {
  margin-right: 8px;
}

.pair-state .pair-state-buttons > .link:last-child {
  margin-right: 0;
}

.pair-state .pair-state-buttons > .link-separator {
  margin-right: 8px;
}

.pair-state .pair-state-buttons > .link-separator:first-of-type {
  display: none;
}

.pair-state .pair-state-thumbnail {
  image-rendering: pixelated;
  background: #000;
  border: 1px solid #fff;
  border-radius: 6px;
  width: 100px;
  padding: 5px;
}

.panel-split {
  display: flex;
}

@media only screen and (width <= 1120px) {
  .panel-split {
    flex-direction: column;
  }
}

.panel-split > .side-left {
  text-align: center;
  flex: 1 0;
  justify-content: center;
  display: flex;
}

.panel-split > .side-right {
  flex: 0;
  min-width: 580px;
  max-width: 100%;
  padding: 0 24px;
}

@media only screen and (width <= 1120px) {
  .panel-split > .side-right {
    min-width: unset;
    padding: 0;
  }
}

.paragraph {
  margin: 12px 0;
  font-size: 18px;
  line-height: 24px;
}

.paragraph.no-margin-top {
  margin-top: 0;
}

.save-info .save-info-thumbnail > img {
  image-rendering: pixelated;
  width: 100%;
}

.section {
  display: none;
}

.section.visible {
  display: block;
}

.section > .separator {
  background: #fff;
  height: 2px;
  margin: 22px 0;
}

.separator {
  clear: both;
  background: #fff;
}

.separator-clear {
  clear: both;
}

.text-input {
  -o-border-radius: 6px 6px 6px 6px;
  -ms-border-radius: 6px 6px 6px 6px;
  -khtml-border-radius: 6px 6px 6px 6px;
  color: #fff;
  -o-outline: none;
  -ms-outline: none;
  -moz-outline: none;
  -khtml-outline: none;
  -webkit-outline: none;
  -o-transition: background-color .35s cubic-bezier(.075, .82, .165, 1);
  -khtml-transition: background-color .35s cubic-bezier(.075, .82, .165, 1);
  background-color: #c8c8c833;
  border: 2px solid #0000;
  border-radius: 6px;
  outline: none;
  width: 100%;
  padding: 10px 14px;
  font-family: VT323, Roboto, Open Sans, Arial, Helvetica, sans-serif;
  font-size: 24px;
  transition: background-color .35s cubic-bezier(.075, .82, .165, 1);
}

.text-input.medium {
  padding: 8px 12px;
  font-size: 20px;
}

.text-input.small {
  padding: 6px 10px;
  font-size: 16px;
}

.text-input::placeholder {
  color: #ffffffbf;
}

.text-input:hover {
  background-color: #c8c8c866;
}

.text-input:focus {
  -o-transition: none;
  -khtml-transition: none;
  background-color: #c8c8c833;
  border-color: #fff;
  transition: none;
}

.title > .link, .title > .label {
  margin-left: 14px;
}

.title > .icon {
  vertical-align: top;
  height: 32px;
  margin-left: 14px;
}

.toast {
  pointer-events: none;
  text-align: center;
  z-index: 8;
  background-color: #000;
  width: 100%;
  height: 0;
  padding: 0 24px;
  position: fixed;
  top: 0;
  left: 0;
}

.toast > .toast-text {
  -o-border-radius: 4px 4px 4px 4px;
  -ms-border-radius: 4px 4px 4px 4px;
  -khtml-border-radius: 4px 4px 4px 4px;
  cursor: pointer;
  opacity: 0;
  -o-opacity: 0;
  -ms-opacity: 0;
  -moz-opacity: 0;
  -khtml-opacity: 0;
  -webkit-opacity: 0;
  -o-transition: top .5s cubic-bezier(.075, .82, .165, 1), opacity .35s cubic-bezier(.075, .82, .165, 1);
  -khtml-transition: top .5s cubic-bezier(.075, .82, .165, 1), opacity .35s cubic-bezier(.075, .82, .165, 1);
  background-color: #3e948a;
  border-radius: 4px;
  width: fit-content;
  padding: 12px 18px;
  font-size: 20px;
  line-height: 22px;
  transition: top .5s cubic-bezier(.075, .82, .165, 1), opacity .35s cubic-bezier(.075, .82, .165, 1);
  display: inline-block;
  position: relative;
  top: -46px;
}

.toast.error > .toast-text {
  background-color: #e63946;
}

.toast.visible > .toast-text {
  opacity: 1;
  -o-opacity: 1;
  -ms-opacity: 1;
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  pointer-events: all;
  top: 24px;
}

.app {
  color: #fff;
  max-width: 1400px;
  margin: 0 auto;
  font-family: VT323, Roboto, Open Sans, Arial, Helvetica, sans-serif;
}

.app h3 {
  margin: 0 0 16px;
}

.app .display-container {
  margin-top: 78px;
}

@media only screen and (width <= 1120px) {
  .app .display-container {
    margin-top: 14px;
  }
}

.audio-gb > .section {
  vertical-align: top;
  display: inline-block;
}

.audio-gb > .section > .audio-wave {
  margin-right: 5px;
  display: inline-block;
}

.audio-gb > .section > .audio-wave.master {
  margin-bottom: 16px;
  display: block;
}

.audio-gb > .section > .audio-wave.selector {
  cursor: pointer;
}

.audio-gb > .section > .audio-wave.disabled {
  opacity: .6;
  -o-opacity: .6;
  -ms-opacity: .6;
  -moz-opacity: .6;
  -khtml-opacity: .6;
  -webkit-opacity: .6;
}

.audio-gb > .section > .audio-wave > h4 {
  margin: 4px 0;
}

.registers-gb > .section {
  vertical-align: top;
  margin-right: 32px;
  display: inline-block;
}

.registers-gb > .section:last-child {
  margin-right: 0;
}

.registers-gb > .section > h4 {
  margin: 0 0 8px;
  font-size: 22px;
}

.registers-gb > .section > .register {
  font-size: 0;
  line-height: 22px;
}

.registers-gb > .section > .register > .register-key {
  width: 40px;
  font-size: 20px;
  display: inline-block;
}

.registers-gb > .section > .register > .register-value {
  text-align: right;
  width: 66px;
  font-size: 20px;
  display: inline-block;
}

.tiles-gb > .canvas {
  -o-border-radius: 8px 8px 8px 8px;
  -ms-border-radius: 8px 8px 8px 8px;
  -khtml-border-radius: 8px 8px 8px 8px;
  background-color: #000;
  border: 2px solid #58b09c;
  border-radius: 8px;
  padding: 8px;
}

.tiles-gb > .canvas.content-box {
  box-sizing: content-box;
  -o-box-sizing: content-box;
  -ms-box-sizing: content-box;
  -khtml-box-sizing: content-box;
}

.keyboard-help {
  margin: 0;
  padding-left: 0;
  font-size: 18px;
  line-height: 22px;
  list-style: none;
}

.keyboard-help > li {
  margin: 12px 0;
}

.keyboard-help .key-container {
  text-align: right;
  min-width: 86px;
  margin-right: 18px;
  display: inline-block;
}

.keyboard-help .key {
  -o-border-radius: 6px 6px 6px 6px;
  -ms-border-radius: 6px 6px 6px 6px;
  -khtml-border-radius: 6px 6px 6px 6px;
  text-align: center;
  border: 2px solid #fff;
  border-radius: 6px;
  min-width: 34px;
  margin-right: 8px;
  padding: 1px 8px;
  font-size: 16px;
  display: inline-block;
}

.keyboard-help .key:last-child {
  margin-right: 0;
}

.faqs-help > h3 {
  margin: 0 0 6px;
}

.faqs-help > p {
  margin: 0 0 22px;
  line-height: 20px;
}

.serial-section .printer > .printer-lines {
  font-size: 0;
}

.serial-section .printer > .printer-lines > .printer-line {
  display: block;
}

.serial-section .printer > .printer-lines > .placeholder {
  font-size: initial;
}
/*# sourceMappingURL=index.12291adc.css.map */
